import React from "react"
import { graphql } from "gatsby"
import PortfolioPart from "../../components/template-parts/portfolio-part"

export default ({ data }) => <PortfolioPart data={data} />

export const query = graphql`
    query portfolio($id: String!, $nextPage: String, $previousPage: String) {
        portfolio: wpPortfolio(id: { eq: $id }) {
            title
            content
            featuredImage {
                node {
                    sourceUrl
                    remoteFile {
                        ...HeroImage
                    }
                }
            }
            seo {
                    title
                    metaDesc
                    canonical
             }
            additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage{
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
            }
        }

        nextPage: wpPortfolio(id: { eq: $nextPage }) {
            title
            uri
        }

        previousPage: wpPortfolio(id: { eq: $previousPage }) {
            title
            uri
        }
    }
`
