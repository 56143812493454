import React from "react"
import sanitizeHtml from 'sanitize-html';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import { normalizePath } from "../../utils/get-url-path"
import sanitizeOptions from "../../helper/sanitizeOptions";

function PortfolioPart({ data }) {
    const sanitizeOpt = sanitizeOptions();
    const { nextPage, previousPage, portfolio} = data
    const { title, content, featuredImage, seo, additionalMetaTag } = portfolio;
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};

    return (
        <Layout
            seoData={seoData}>
            <h1 className={`title`}>
                {title}
            </h1>

            {!!featuredImage?.node?.remoteFile?.childImageSharp && (
                <div>
                    <Img fluid={featuredImage.node.remoteFile.childImageSharp.fluid} />
                </div>
            )}

            <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(content, sanitizeOpt.title) }} />

            {!!nextPage && (
                <AniLink to={normalizePath(nextPage.uri)}>Next: {nextPage.title}</AniLink>
            )}

            {!!previousPage && (
                <AniLink to={normalizePath(previousPage.uri)}>
                    Previous: {previousPage.title}
                </AniLink>
            )}
        </Layout>
    )
}

export default PortfolioPart
